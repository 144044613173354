import styles from './nav.module.css';
import { NavLink } from 'react-router-dom';

function LiAnchor({ id, text, href, onSelect, active = false }) {
	if (active) {

		return (
			<div className={styles.li_anchor}>
				<li className={styles.list_item}>

					<NavLink className={styles.activeLink } to={ href }>{ text }</NavLink>
				</li>
			</div>
		);
	} else {
		return (
			<div className={styles.li_anchor}>
				<li className={styles.list_item}>
					<NavLink className={styles.anchor} to={ href } onClick={e => {onSelect(id)}}>{ text }</NavLink>

				</li>
			</div>
		);
	}
}

export { LiAnchor };
