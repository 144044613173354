import styles from './nav.module.css';
import { LiAnchor } from './li_a.js';
import { useEffect, useState } from 'react';
import styles2 from './nameLogo.module.css';
import {ReactComponent as MenuIcon} from '../images/menu_stack.svg';
import {ReactComponent as CloseIcon} from '../images/close_icon.svg';
import { NavLink } from 'react-router-dom';


function NavBar({ anchorElmts }) {
	const [activeLink, setActiveLink] = useState(0);
	
	function handleSelect(anchorId) {
		setActiveLink(anchorId);
	}

	const [menu, toggleMenu] = useState(false);

	function handleMenuToggle() {
		toggleMenu(!menu);
	}

	return (
		<nav className={styles.navigation}>
			<div className={styles.inner_nav_container}>
				<div className={styles2.svg_container}>
					<svg width="105" xmlns="http://www.w3.org/2000/svg">
						<text y="70%" className={styles2.firstName}>Randy</text>
					</svg>
					<svg  width="100" xmlns="http://www.w3.org/2000/svg">
						<text y="70%" className={styles2.lastName}>Tang</text>
					</svg>
				</div>
				<div className={styles.anchor_container}>
					<ul className={styles.ul}>

						{anchorElmts.map(anchor => {
							if (activeLink === anchor.id) {
								return (
									<LiAnchor key={anchor.id} id={anchor.id} text={anchor.text} href={anchor.href } onSelect={handleSelect} active={true} />
								)
							} else {
								return (
									<LiAnchor key={anchor.id} id={anchor.id} text={anchor.text} href={anchor.href } onSelect={handleSelect} />
								)
							}
						})}
					</ul>
				</div>

				<div className={styles.nav_icons_container}>
					{menu ? <CloseIcon onClick={handleMenuToggle} className={styles.close_icon} /> : <MenuIcon onClick={handleMenuToggle} className={styles.menu_icon} />}
				</div>
			</div>

			<div className={menu ? styles.active_menu : styles.inactive_menu}>
					<ul className={styles.ul_mobile}>
						{/*
						{anchorElmts.map(anchor => {
							if (activeLink === anchor.id) {
								return (
									<LiAnchor key={anchor.id} id={anchor.id} text={anchor.text} href={anchor.href } onSelect={handleSelect} active={true} />
								)
							} else {
								return (
									<LiAnchor key={anchor.id} id={anchor.id} text={anchor.text} href={anchor.href } onSelect={handleSelect} />
								)
							}
						})}

						*/ }
						{anchorElmts.map(anchor => {
							return (
								<NavLink onClick={handleMenuToggle} className={styles.mobile_menu_link} to={ anchor.href }>{ anchor.text }</NavLink>
							)
						})}
					</ul>
			</div>
			
		</nav>
	);
}

export default NavBar;
