import forwardArrow from '../images/icons8-forward-button-96.png'
import backArrow from '../images/icons8-back-arrow-96.png'

function ScrollButton({ direction = "left", targetElmt, onClick = f => f }) {
    return (
        <img src={direction === "left" ? backArrow : forwardArrow} onClick = {() => onClick(direction, targetElmt)} />
    );
} 


export { ScrollButton };