import { Field, useFormik, FormikProvider } from 'formik';
import styles from './form.module.css';
import { useRef, useState } from 'react';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';

var classnames = require('classnames');



const validate = values => {
	const errors = {};

	if (!values.email) {
		errors.email = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	if (values.phone) {
		if (!/\d{10}/.test(values.phone)) {
			errors.phone = "Phone has to be 10 digits";
		}
	}


	return errors
};


export const ContactForm = () => {
    let [formSubmitted, setSubmitted] = useState(false);
    const formRef = useRef(null);
    const recaptchaRef = useRef(null);
	const formik = useFormik({
		initialValues: {
			name: '',
			phone: '',
			email: '',
			message: 'Type your message...',
            recaptcha: '',
		},
		validate,
		onSubmit:  (values) => {
            const recaptchaToken = recaptchaRef.current.getValue();
            console.log(recaptchaToken);
            const addedToken = {...values, recaptcha: recaptchaToken }
            //axios.post('http://127.0.0.1:3005/contact/submit', values)
            //axios.post('http://127.0.0.1:3005/contact/submit',addedToken)
            axios.post('https://contact.randytang.net/contact/submit',addedToken)
            //axios.post('https://contact.randytang.net/contact/submit', values)
                .then((response) => { 
                    if (response.status == 200 || response.status == 204) {
                        setSubmitted(true);
                    } 
                })
                .catch(err => {
                    console.error(err);
                })
			//alert(JSON.stringify(values, null, 2));
		},
	});

    if (formSubmitted) {
        return (
            <Navigate to="/contact/success" />
        );
    } else {
        return (
            <form ref={formRef} onSubmit={formik.handleSubmit}>
                <div className={styles.form_box}>
                    <div className={styles.form_grid}>
                        <div className={styles.input_label}>
                            <label className={styles.label} htmlFor="name">Name:</label>
                            <div className={styles.input_box}>
                                <input
                                    className={styles.input}
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                            />

                            </div>
                        </div>
                        
                        <div className={styles.input_label}>
                            <label className={styles.label} htmlFor="phone">Phone:</label>
                                <div className={styles.input_box}>
                                    <input
                                        className={styles.input}
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div>{formik.errors.phone}</div>
                                    ) : null}
                                </div>
                        </div>

                        <div className={styles.input_label}>
                            <label className={styles.label} htmlFor="email">Email:</label>
                                <div className={styles.input_box}>
                                    <input
                                        className={styles.input}
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                </div>
                                    {formik.errors.email ? <div className={styles.validation_error}>{formik.errors.email}</div> : null}
                        </div>

                        <div className={styles.input_label}>
                            <label className={styles.label} htmlFor="message">Message:</label>
                                <div className={styles.input_box}>
                                    <FormikProvider value={formik}>
                                        <Field 
                                            className={styles.input + " " + styles.textarea_ipt}
                                            name="message"
                                            as="textarea"
                                        />
                                    </FormikProvider>
                                </div>
                        </div>


                        <FormikProvider value={formik}>
                            <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_PROD_SITE_KEY}
                            onChange={formik.handleChange}
                            />
                            {formik.touched.recaptcha && formik.errors.recaptcha ? (
                            <div className={styles.validation_error}>{formik.errors.recaptcha}</div>
                            ) : null}
                        </FormikProvider>

                        <button className={styles.form_btn} type="submit">Submit</button>
                    </div>
                </div>
            </form>
        )
    }
};
				
			
