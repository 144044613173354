import styles from './FormSuccess.module.css';

function FormSuccess() {
    return (
        <div className={styles.FormSuccess}>
            <div className={styles.msg_box}>
                <p className={styles.msg}>{"Message sent. Thanks for reaching out. I'll get back to you as soon as I can."}</p>
            </div>
        </div>
    )

}

export { FormSuccess };