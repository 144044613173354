import { useState, useRef, useEffect } from 'react';
import styles from '../body.module.css';
//import profilePic from '../images/trooper_cropped.png';
import profilePic from '../images/profile.jpg';
import rphPic from '../images/rph_wag.jpg';
import techPic from '../images/helpdesk.jpg';
import ricSeal from '../images/ric_seal.svg';
import uconnSeal from '../images/uconn_leaf.png';
import cssLogo from '../images/css.png';
import dockerLogo from '../images/docker.webp';
import htmlLogo from '../images/html.png';
import javaLogo from '../images/java.png';
import javascriptLogo from '../images/javascript.png';
import pythonLogo from '../images/python.png';
import reactLogo from '../images/react.png';
import tuxLogo from '../images/tux.png';
import vimLogo from '../images/vim.png';
import { ScrollButton } from '../components/scroll_button';
import { MyCard } from '../components/myCard';
import card_pic_1 from '../images/card_pic_1.webp'
import card_pic_2 from '../images/card_pic_2.webp'
import card_pic_3 from '../images/card_pic_3.jpg'
import card_pic_4 from '../images/card_pic_4.jpg'


const classnames = require("classnames");


const testimonialData = [
	{active: true, "img_source": card_pic_1, "title": "Dave, Director of User Support Services", "card_text": "Randy is a hardworking, diligent, and highly knowledgeable technician. He provided excellent customer service and technical assistance.", "btn_text": "Read More" },
    {"img_source": card_pic_2, "title": "Mario, Senior Technician of User Support Services", "card_text": "Randy's knowledge of desktop support is invaluable. He is thorough and highly motivated and a great team player.", "btn_text": "Read More" },
	{"img_source": card_pic_3, "title": "Luigi, Senior Software Engineer of User Support Services", "card_text": "Randy is a 10x programmer and can deliver a product that is highly scalable and maintainable.", "btn_text": "Read More" },
	{"img_source": card_pic_4, "title": "Yoshi, Junior Web Developer of User Support Services", "card_text": "Whenever I'm stuck on a problem, Randy is able to help me troubleshoot, debug, and resolve any technical issues I have.", "btn_text": "Read More" },
]

function Home() {

	const [isHover, setIsHover] = useState(false);


	function handleMouseEnter() {
		setIsHover(true);
	}

	function handleMouseLeave() {
		setIsHover(false);
	}

	function useHorizontalScroll() {
		const elRef = useRef();
		useEffect(() => {
			const el = elRef.current;
			if (el) {
				const onWheel = e => {
					if (e.deltaY == 0) return;
					e.preventDefault();
					el.scrollTo({
						left: el.scrollLeft + (e.deltaY * 5),
						behavior: "smooth"
					});
				};
				el.addEventListener("wheel", onWheel);
				return () => el.removeEventListener("wheel", onWheel);
			}
		}, []);
		return elRef;
	}
	const scrollRef = useHorizontalScroll();

	const testimonialRef = useRef(null);

	let [activeId, setActiveId] = useState(0);

	function handleScrollClick(direction, targetElmt) {
		const scrollOffset = direction === "left" ? -200 : 200;

		targetElmt.current.scrollTo({
			left: targetElmt.current.scrollLeft + scrollOffset,
			behavior: "smooth"
		});
	}

	function handleTestimonialScrollClick(direction, targetElmt) {
		if (direction === "right") {
			if (activeId === 3) {
				setActiveId(0);
			} else {
				setActiveId(activeId % 3 + 1);
			}
		} else {
			if (activeId === 0) {
				setActiveId(3);
			} else {
				setActiveId(activeId - 1);
			}
		}
		setFirstRender(false);
	}

	let [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		if (!firstRender) {
			testimonialRef.current.scrollIntoView({ behavior: "smooth", inline: "center"});
		} 
	})

	const hoverStyle = {
		marginTop: '1rem',
		background: '#333333',
		color: '#ffffff',
		padding: '5px 10px',
		width: '100%',
		boxSizing: 'border-box',
		/*
		position: 'absolute',
		*/
		textAlign: 'center',
		borderRadius: '3px',
		visibility: isHover ? 'visible' : 'hidden',
		opacity: isHover ? 1 : 0,

	};



  return (
    <div className="home_wrapper">
	  <div className={styles.first_container}>
		<div className={styles.about_me_container}>
		 	<div>
				<img className={styles.profile_pic + " " + styles.img_shadow} src={profilePic} onMouseEnter={handleMouseEnter}  onMouseLeave={handleMouseLeave}/>
				<div style={hoverStyle }>
					<p>Yes, that is a real PDP-9 behind me at the RICM. And no, not quite. I was born in 1989. Supposedly, that is the only functioning PDP-9 left in the world and I happen to live 4 minutes from where it's running.</p>
				</div>
			</div>
			<div className={styles.about_me_text_container}>
				<h1 className={styles.h1_about_me}>{"Aspiring Developer"}</h1>
				<p className={styles.p_about_me}>{"Interested in building proven software solutions that are modern, scalable, and impactful."}</p>

			</div>
		</div>
	  </div>
		<div className={styles.second_container_spanning}>
			<div className={styles.inner_second_container}>
				<h1 className={styles.second_container_h1}>{"Making an impact."}</h1>
				<p className={styles.second_container_text}>
					{"Why pharmacy to information technology? Or more specifically, software development? Working as a retail pharmacist the past 9 years has given me an opportunity to make an impact on local communities by helping to improve the health and well being of people from these communities using my clinical knowledge and skills "}<span className={styles.highlight}>{"but now I'm interested in using technology, specifically software,"}</span>{" to provide the same outcomes if not better."}
				</p>
			</div>
		</div>

		<div className={styles.third_container}>
			<div className={styles.experience_container}>
				<div className={styles.experience_text_container}>
					<h1 className={styles.experience_h1}>{"Experience"}</h1>
					<div className={styles.experience_rph_list}>
						<h2 className={styles.experience_rph_h2}>{"Walgreens"}</h2>
						<ul className={styles.experience_rph_ul}>
							<li className={styles.experience_rph_li}><i>{"District Pharmacist"}</i></li>
							<li className={styles.experience_rph_li}>{"May 2013 - Present"}</li>
							<p className={styles.experience_rph_p}>{"Multitasking, attention to detail, performing under time/resource constraints are some of the skills pharmacy has taught me that have helped me become a better software developer. Also being an end user of the internal software systems of a healthcare operation has given me insight into how crucial the scalability and uptime of software can be to our day-to-day tasks. I now hope to be on the other end of the table as a developer to deliver mission critical and resilient software that has allowed us to provide quality healthcare."}</p>
						</ul>
					</div>
				</div>
				<div className={styles.img_box}>
					<img className={styles.experience_rph_pic + " " + styles.img_shadow} src={rphPic} />
				</div>
			</div>
		</div>


		<div className={styles.fourth_container}>
			<div className={styles.experience_container}>
				<div className={styles.experience_text_container}>
					<div className={styles.experience_tech_list}>
						<h2 className={styles.experience_tech_h2}>{"Rhode Island College"}
						</h2>
						<ul className={styles.experience_tech_ul}>
							<li className={styles.experience_tech_li}><i>{"Help desk technician"}</i></li>
							<li className={styles.experience_tech_li}>{"August 2021 - December 2022"}</li>
							<p className={styles.experience_tech_p}>{"Provided technical support and troubleshooting to help resolve tickets submitted by students and faculty related to a wide range of issues such as password/account management, classroom audio/video equipment, printer configuration, computer desktop imaging and setup. The problems we helped resolve were largely software based such as Outlook, Zoom, etc and searching for solutions really helped me hone in on my research/documentation skills a.k.a googling. But one of the important things I took away from this experience was an appreciation for cross platform support of applications, specifically, how most modern apps are web/browser oriented vs desktop which influenced me to focus on learning and developing web technologies."}
							</p>
						</ul>
					</div>
				</div>
				<img className={styles.experience_tech_pic + " " + styles.img_shadow} src={techPic} />
			</div>
		</div>

		<div className={styles.fifth_container}>
			<h1 className={styles.education_h1}>{"Education"}</h1>
			<div className={styles.edu_ric_container}>
				<img className={styles.edu_logo} src={ricSeal} />
				<div className={styles.edu_text_container}>
					<h1 className={styles.edu_h1 + " " + styles.edu_ric_h1}>{"Rhode Island College"}</h1>
					<ul className={styles.edu_ul}>
						<li className={styles.edu_li}>{"Providence, RI"}</li>
						<li className={styles.edu_li}>{"January 2016 - December 2022"}</li>
						<li className={styles.edu_li}><i>{"Bachelor of Arts Computer Science"}</i></li>
					</ul>
				</div>
			</div>
		</div>
		<div className={styles.sixth_container}>
			<div className={styles.edu_uconn_container}>
				<img className={styles.edu_logo} src={uconnSeal} />
				<div className={styles.edu_text_container}>
					<h1 className={styles.edu_h1 + " " + styles.edu_uconn_h1}>{"University of Connecticut"}</h1>
					<ul className={styles.edu_ul}>
						<li className={styles.edu_li}>{"Storrs, CT"}</li>
						<li className={styles.edu_li}>{"August 2009 - May 2013"}</li>
						<li className={styles.edu_li}><i>{"Doctor of Pharmacy"}</i></li>
					</ul>
				</div>
			</div>
		</div>


		<div className={styles.seventh_container} >
			<h1 className={styles.skills_h1}>{"Skills"}</h1>
			<div className={styles.skills_button_and_logos}>
				<div className={styles.back_arrow_container}>
					<ScrollButton targetElmt={scrollRef} onClick={handleScrollClick}></ScrollButton>
				</div>
				<div className={styles.skills_container} ref={scrollRef} >
					<img className={styles.skills_logo} src={pythonLogo} alt={"Python"} />	
					<img className={styles.skills_logo} src={htmlLogo} alt={"HTML"} />	
					<img className={styles.skills_logo} src={javaLogo} alt={"Java"} />	
					<img className={styles.skills_logo} src={javascriptLogo} alt={"JavaScript"} />	
					<img className={styles.skills_logo} src={dockerLogo} alt={"Docker"} />	
					<img className={styles.skills_logo} src={reactLogo} alt={"React"} />	
					<img className={styles.skills_logo} src={tuxLogo} alt={"Linux"} />	
					<img className={styles.skills_logo} src={vimLogo} alt={"vim"} />	
					<img className={styles.skills_logo} src={cssLogo} alt={"CSS"} />	
				</div>
				<div className={styles.forward_arrow_container}>
					<ScrollButton targetElmt={scrollRef} direction="right" onClick={handleScrollClick}></ScrollButton>
				</div>
			</div>
		</div>


		{/*	
		<div className={styles.eigth_container}>
			<div className={styles.testimonial_box}>
				<h1 className={styles.testimonials_h1}>{"Testimonials"}</h1>
				<div className={styles.scrollButtons_scrollContent_box}>
					<div className={styles.back_arrow_container}>
						<ScrollButton targetElmt={testimonialRef} onClick={handleTestimonialScrollClick}></ScrollButton>
					</div>
					<div className={styles.testimonial_cards}>
							{testimonialData.map((testimonial, i) => {
										return (
											<div className="card-wrapper">
												<MyCard ref={i === activeId ? testimonialRef: null} active={i === activeId ? true : false}  img_src={testimonial.img_source} title={testimonial.title} card_text={testimonial.card_text} btn_text={testimonial.btn_text} />
											</div>
										)
									}
							)}
					</div>
					<div className={styles.forward_arrow_container}>
						<ScrollButton targetElmt={testimonialRef} direction="right" onClick={handleTestimonialScrollClick}></ScrollButton>
					</div>
				</div>
			</div>
		</div>
		
		*/}
	
		<div className={styles.footer_wrapper}>
			<div className={styles.footer}>
				<div className={styles.footer_col_one}>
					<p>{"Handcrafted with "}<span className={styles.react_span}>{"React "}</span>{"and powered by"}<span className={styles.nginx_span}>{" nginx "}</span>{"and "}<span className={styles.aws_span}>{"aws"}</span>{"."}</p>
				</div>
				<div className={styles.social_wrapper}>
					<a className={styles.nav_icon} href={"https://github.com/randytang"} aria-label={"Github"}>
						<svg class="svg-inline--fa fa-github fa-w-16 fa-lg" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" data-fa-i2svg=""><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
					</a>

					<a className={styles.nav_icon} href={"https://stackoverflow.com/users/1618472/randy-tang?tab=profile"} aria-label={"StackOverflow"}>
						<svg class="svg-inline--fa fa-stack-overflow fa-w-12" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="stack-overflow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M290.7 311L95 269.7 86.8 309l195.7 41zm51-87L188.2 95.7l-25.5 30.8 153.5 128.3zm-31.2 39.7L129.2 179l-16.7 36.5L293.7 300zM262 32l-32 24 119.3 160.3 32-24zm20.5 328h-200v39.7h200zm39.7 80H42.7V320h-40v160h359.5V320h-40z"></path></svg>
					</a>
				</div>
			</div>

		</div>
	
    </div>
  );
}

export default Home;

