import { forwardRef } from 'react';
import styles from './MyCard.module.css';


const classnames = require('classnames');

const MyCard = forwardRef(({ title = "Title", img_src = "...", card_text = "...", btn_text = "Click", active}, ref) => {
    return (
        <div className={classnames(styles.card, {[styles.active]: active})} ref={active ? ref: null}>
            <img className={styles.card_img_top} src={img_src} alt="Card image cap" />
            <div className={styles.card_body}>
                <h5 className={styles.card_title}>{title}</h5>
                <p className={styles.card_text}>{card_text}</p>
                <a href="#" className={classnames(styles.btn, styles.btn_primary)}>{btn_text}</a>
            </div>
        </div>
    )
});


export { MyCard };