import './App.css';
import NavBar from './components/nav.js';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import { useState } from 'react';
import { FormSuccess } from './Pages/formSuccess';


const initialList = [
	{ id: 0, text: "About", href: "/" },
	{ id: 2, text: "Contact", href: "/contact" },
]


function App() {

	const [listOfAnchors, setListOfAnchors] = useState(initialList);
	let [scrollYPos, setScrollYPos] = useState({});
	
	function handleRouteClick() {
		setScrollYPos({
			...scrollYPos,
			activeLink : window.scrollY
		});
	}


  return (
    <div className="App">
	  <NavBar anchorElmts={listOfAnchors} />
	  <Routes>
	  	<Route path="/" element={<Home />} />
	  	<Route path="/contact" element={<Contact />} />
		<Route path="/contact/success" element={<FormSuccess />} />
	  </Routes>

    </div>
  );

}

export default App;
